import React, { useEffect, useState } from 'react';
import { ref, listAll, getDownloadURL } from 'firebase/storage';
import { storage } from "../api/firebaseConfig";
import { motion } from "framer-motion";
import Loader from './Loader';
import Calendar from "react-calendar";
import MonthPicker from "./MonthPicker";
import MonthFilter from "./MonthFilter";

// Custom container animation (vertical)
const containerVariants = {
	hidden: { opacity: 0, y: 20 },
	visible: { opacity: 1, y: 0 },
	exit: { opacity: 0, y: -20 }
};

// Map month number to month name
const getMonthName = (monthNumber: string) => {
	const months = [
		"January", "February", "March", "April", "May", "June",
		"July", "August", "September", "October", "November", "December"
	];
	return months[parseInt(monthNumber) - 1]; // Month numbers are 0-indexed in arrays
};

// Format file name to "Report for [Month] [Year].xlsx"
const formatReportName = (name: string) => {
	// Assuming the name format is YYYY-MM (e.g., "2024-09")
	const [year, month] = name.split("-");
	const monthName = getMonthName(month);
	return `Report for ${monthName} ${year}.xlsx`;
};

const Reports = () => {
	const [reports, setReports] = useState<{ name: string, url: string }[]>([]);
	const [filteredReports, setFilteredReports] = useState<{ name: string, url: string }[]>([]);
	const [selectedMonth, setSelectedMonth] = useState<number>(new Date().getMonth());
	const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
	const [filterMonth, setFilterMonth] = useState<string>('');
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if(selectedMonth == 0) {
			setFilterMonth(`all`)
		} else {
			setFilterMonth(`${selectedYear}-${selectedMonth < 10 ? `0${selectedMonth}` : selectedMonth}`);
		}
	}, [selectedMonth, selectedYear]);

	useEffect(() => {
		if(filterMonth == 'all') {
			setFilteredReports(reports);
		} else {
			console.log(reports, filterMonth);
			setFilteredReports(reports.filter(report => report.name == `${filterMonth}.xlsx`));
		}
	}, [filterMonth]);

	useEffect(() => {
		// Reference to the 'reports' folder in Firebase Storage
		const reportsRef = ref(storage, 'reports/');

		// Fetch the reports
		listAll(reportsRef)
			.then(async (res) => {
				const files = await Promise.all(
					res.items.map(async (itemRef) => {
						const url = await getDownloadURL(itemRef);
						// Format the name to a more readable format
						// const formattedName = formatReportName(itemRef.name);
						return { name: itemRef.name, url };
					})
				);
				setReports(files);
			})
			.catch((error) => {
				console.error('Error fetching reports:', error);
			})
			.finally(() => {
				setLoading(false); // Set loading to false when done
			});
	}, []);

	// Format selected date to match report name format (YYYY-MM)
	const formatSelectedDate = (date: Date) => {
		const year = date.getFullYear();
		const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Ensure 2 digits for month
		return `${year}-${month}`;
	};

	// const filteredReports = selectedDate
	// 	? reports.filter(report => report.name.includes(formatSelectedDate(selectedDate))) // Filter based on selected date
	// 	: reports; // Show all if no date is selected
	//
	// const handleDateChange = (date: any) => {
	// 	if (date && !Array.isArray(date)) {
	// 		setSelectedDate(date); // Ensure it's a Date object and not an array
	// 	}
	// };

	const handleMonthChange = (newMonth: number) => {
		setSelectedMonth(newMonth);
	};



	return (
		<div>
			<div className="">
				<div className="">
					<MonthFilter
						selectedMonth={selectedMonth}
						setSelectedMonth={handleMonthChange}
						selectedYear={selectedYear}
						setSelectedYear={setSelectedYear}
						includeAllOption={true}
					/>
				</div>
				<div className="">
					{loading ? (
						// Display the custom Loader component while data is loading
						<div className="flex justify-center items-center h-[50vh]">
							<Loader />
						</div>
					) : (
						<motion.ul
							key={filterMonth}
							variants={containerVariants}
							initial="hidden"
							animate="visible"
							exit="exit"
							transition={{ type: 'spring', stiffness: 300, damping: 30 }}
						>
							{filteredReports.length > 0 ? (
								filteredReports.map((report) => (
									<li className="bg-torch-red-500 p-5 rounded-2xl text-white flex justify-between items-center" key={report.name}>
										<p className="font-black">
											{formatReportName(report.name)}
										</p>
										<a href={report.url} target="_blank" rel="noopener noreferrer" className="p-2 bg-white font-black rounded-2xl uppercase text-torch-red-500">
											Download
										</a>
									</li>
								))
							) : (
								<li>No reports found for the selected date.</li>
							)}
						</motion.ul>
					)}
				</div>
			</div>
		</div>
	);
};

export default Reports;
