import React from 'react';

export type ContainerProps = React.HTMLAttributes<HTMLDivElement> & {
	children: React.ReactNode;
};

const Container = ({children, className, ...rest} : ContainerProps) => {
	return (
		<div className={`w-[90%] mx-auto ${className}`} {...rest}>
			{children}
		</div>
	);
}

export default Container;
