import React, {useState} from 'react';
import {fetchCardId, submitData} from "../api/apiService";
import Container from "../components/Container";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faRefresh, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import ShiftItem from "../components/ShiftItem";
import {doc, getDoc, setDoc} from "firebase/firestore";
import {db} from "../api/firebaseConfig";
import {useToast} from "../tools/ToastContext";
import {useAuth} from "../tools/AuthContext";

export interface CardData {
	cardId: string,
	restaurantIds: string[] | string, // Changed to an array
	deviceId: string,
	deviceType: string
}

// export interface CardDataResponse {
// 	cardId: string,
// 	restaurantId: string, // Changed to an array
// 	deviceId: string,
// 	deviceType: string
// }


const RegisterUser = () => {

	const {addToast} = useToast();
	const {currentUser, restaurant} = useAuth();
	const [cardId, setCardId] = useState<string | null>(null);
	const [name, setName] = useState<string>("");
	const [email, setEmail] = useState<string>("");
	const [selectedRestaurants, setSelectedRestaurants] = useState<string[]>([]); // Array of selected restaurants
	const [restaurantOptions, setRestaurantOptions] = useState<{ id: string; label: string; }[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [shifts, setShifts] = useState<{ from: string; to: string; restaurantId: string }[]>([]);

	const restaurants = [
		{ id: 'antwerp', label: 'Antwerp' },
		{ id: 'smash', label: 'Smash' },
		{ id: 'mechelen', label: 'Mechelen' }
	];

	const handleAddShift = () => {
		if (shifts.length < 3) {
			const defaultRestaurant = restaurant == 'all' ? '' : restaurant;
			setShifts([...shifts, { from: '', to: '', restaurantId: defaultRestaurant! }]);
		} else {
			addToast('warning', "You can only add up to 3 shifts.");
		}
	};



	const handleShiftChange = (index: number, key: 'from' | 'to' | 'restaurantId', value: string) => {
		const updatedShifts = shifts.map((shift, i) =>
			i === index ? { ...shift, [key]: value } : shift
		);
		setShifts(updatedShifts);
	};


	const handleFetchCardId = async () => {
		setLoading(true);
		const data: CardData | null = await fetchCardId(); // Use the API function from the separate file

		if (data) {
			setCardId(data?.cardId);

			// Fetch user data from Firebase
			const cardRef = doc(db, "users", data.cardId);
			const cardSnapshot = await getDoc(cardRef);

			if (cardSnapshot.exists()) {
				const cardData = cardSnapshot.data();

				// Pre-populate fields with existing data
				setName(cardData.name || "");
				setEmail(cardData.email || "");
				setShifts(cardData.shifts || []);
				setSelectedRestaurants(cardData.restaurantIds || []);
				setRestaurantOptions(restaurants.filter(item => cardData.restaurantIds.includes(item.id)));


				addToast("success", "Card fetched successfully and data prefilled!");
			} else {
				addToast("success", "Card fetched successfully! No user data found, please fill in the details.");
			}
		}

		setLoading(false);
	};

	const handleRemoveShift = async (index: number) => {
		const shiftToRemove = shifts[index];

		// Only allow removing shifts if the user has access to the restaurant or has "all" permissions
		// if (restaurant === "all" || selectedRestaurants.includes(shiftToRemove.restaurantId)) {
		if (restaurant === "all" || restaurant === shiftToRemove.restaurantId) {
			const updatedShifts = shifts.filter((_, i) => i !== index);
			setShifts(updatedShifts); // Update the state

			// Update Firestore with the new shifts
			if (cardId) {
				try {
					const cardRef = doc(db, "users", cardId);
					await setDoc(cardRef, { shifts: updatedShifts }, { merge: true });
					addToast('success', `Shift removed and saved successfully!`);
				} catch (error) {
					console.error("Error updating document: ", error);
					addToast('error', "Error removing the shift.");
				}
			}
		} else {
			addToast('error', "You don't have permission to remove this shift.");
		}
	};



	const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setName(event.target.value);
	};

	const handleCheckboxChange = (restaurantId: string) => {
		if (selectedRestaurants.includes(restaurantId)) {
			setSelectedRestaurants(selectedRestaurants.filter(id => id !== restaurantId));
			setRestaurantOptions(restaurantOptions.filter(item => item.id !== restaurantId));

		} else {
			setSelectedRestaurants([...selectedRestaurants, restaurantId]);
			setRestaurantOptions([...restaurantOptions, restaurants.find(item => item.id == restaurantId)!]);
		}

	};

	const doShiftsCollide = (newShift: { from: string; to: string }, existingShift: { from: string; to: string }) => {
		// Convert the times to Date objects for comparison
		// const newFrom = new Date(`1970-01-01T${newShift.from}:00`);
		// const newTo = new Date(`1970-01-01T${newShift.to}:00`);
		// const existingFrom = new Date(`1970-01-01T${existingShift.from}:00`);
		// const existingTo = new Date(`1970-01-01T${existingShift.to}:00`);

		// Check for overlap
		// const isOverlap = newFrom < existingTo && newTo > existingFrom;
		// return isOverlap;

		return false;
	};

	const handleSubmit = async () => {
		if (name && cardId && shifts.length > 0) {
			await submitData(name, email, cardId, selectedRestaurants, shifts);  // Add selected restaurants and shifts to the submission
		} else {
			addToast("warning", "Please complete all required fields.");
		}
	};

	const submitData = async (
		name: string,
		email: string,
		cardId: string,
		restaurantIds: string[],
		newShifts: { from: string; to: string; restaurantId: string }[]
	) => {
		try {
			const cardRef = doc(db, "users", cardId);
			const cardSnapshot = await getDoc(cardRef);

			if (cardSnapshot.exists()) {
				const cardData = cardSnapshot.data();

				// Extract existing shifts and restaurant IDs from the database
				const existingShifts = cardData.shifts || [];
				const existingRestaurants = cardData.restaurantIds || [];

				// Filter out new shifts that already exist in the current shifts list
				const uniqueNewShifts = newShifts.filter((newShift) =>
					!existingShifts.some(
						(existingShift: any) =>
							existingShift.from === newShift.from &&
							existingShift.to === newShift.to &&
							existingShift.restaurantId === newShift.restaurantId
					)
				);

				// If there are no unique shifts to add, show a warning and return
				if (uniqueNewShifts.length === 0) {
					addToast("warning", "No new shifts to add. Shifts are already registered.");
					return;
				}

				// Append the unique new shifts to the existing ones
				const updatedShifts = [...existingShifts, ...uniqueNewShifts];

				// Merge restaurant IDs, ensuring no duplicates
				const updatedRestaurants = [...new Set([...existingRestaurants, ...restaurantIds])];

				// Update the user document with new shifts and restaurants
				await setDoc(
					cardRef,
					{
						...cardData,
						shifts: updatedShifts,
						restaurantIds: updatedRestaurants,
					},
					{ merge: true }
				);

				addToast("success", "User shifts updated successfully!");
			} else {
				// If the card is not registered, register the user with the provided data
				await setDoc(cardRef, {
					name,
					email,
					cardId,
					restaurantIds, // Submit array of selected restaurant IDs
					shifts: newShifts, // Set the initial shifts
				});
				addToast("success", "User registered successfully!");
			}
		} catch (error) {
			console.error("Error updating document: ", error);
			addToast("error", "Error registering user.");
		}
	};




	return (
		<div>
			<Container className="grid grid-cols-2 gap-5 ">
				<div className="">
					<div className="">
						<h1 className="text-2xl uppercase font-bold m-4">Employee Info</h1>
						<div className="w-full border rounded-2xl">
							<div className="p-2 flex items-center mx-5 space-x-4">
								<label className="w-1/5">Card ID</label>
								<input
									type="text"
									className="flex-grow p-2 rounded border"
									value={cardId ? cardId : 'Waiting for scan...'}
									disabled
								/>
								<div className="flex cursor-pointer" onClick={handleFetchCardId}>
									<FontAwesomeIcon
										icon={faRefresh}
										className={`transition ${loading ? 'animate-spin' : ''}`}
									/>
								</div>
							</div>

							<div className="p-2 flex items-center mx-5 space-x-4">
								<label className="w-1/5">Location</label>
								<div className="flex-grow space-y-2">
									<div className="flex justify-evenly">
										{restaurants.map((restaurantOption) => (
											<div key={restaurantOption.id}>
												<label className="inline-flex items-center">
													<input
														type="checkbox"
														disabled={restaurant !== 'all' && restaurant !== restaurantOption.id}
														checked={selectedRestaurants.includes(restaurantOption.id)} // Pre-check based on selectedRestaurants
														onChange={() => handleCheckboxChange(restaurantOption.id)}
														className="mr-2"
													/>
													{restaurantOption.label}
												</label>
											</div>
										))}
									</div>
								</div>
							</div>

							<div className="p-2 flex items-center mx-5 space-x-4">
								<label className="w-1/5">Name</label>
								<input
									type="text"
									value={name}
									onChange={(e) => setName(e.target.value)}
									className="flex-grow p-2 rounded border"
								/>
							</div>

							<div className="p-2 flex items-center mx-5 space-x-4">
								<label className="w-1/5">Email</label>
								<input
									type="text"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									className="flex-grow p-2 rounded border"
								/>
							</div>

							<div className="flex justify-center items-center">
								<div
									className="px-5 py-2 my-5 text-white font-bold cursor-pointer bg-torch-red-500 rounded-2xl"
									onClick={handleSubmit}
								>
									Save
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="">
					<h1 className="text-2xl uppercase font-bold m-4">Working Schedule</h1>
					<div className="flex flex-col">
						{shifts.map((shift, index) => {
							const canEdit = restaurant === "all" || restaurant === shift.restaurantId;
							console.log(restaurant, shift.restaurantId);
							const isNewShift = !shift.restaurantId; // Check if it's a newly added shift
							const restaurantsToDisplay = restaurant == 'all' ? restaurants :
								restaurants.filter(item => item.id == restaurant)

							return (
								<div key={index} className="flex items-center space-x-4">
									<ShiftItem
										shiftNumber={index + 1}
										shift={shift}
										onChange={(key, value) => handleShiftChange(index, key, value)}
										selectedRestaurants={isNewShift ? restaurantOptions : restaurants.filter(
											(restaurantOption) => restaurantOption.id === shift.restaurantId
										)}
										disabled={!isNewShift && !canEdit}
									/>
									{/* Only show the delete icon if the user can edit the shift */}
									{canEdit && (
										<FontAwesomeIcon
											icon={faTrashAlt}
											className="text-red-500 cursor-pointer"
											onClick={() => handleRemoveShift(index)}
										/>
									)}
								</div>
							);
						})}



						<div className="flex justify-center my-5">
							<div className="border flex rounded-full px-3 text-gray-400 cursor-pointer"
							     onClick={handleAddShift}>
								<FontAwesomeIcon icon={faPlus} size="2x"/>
							</div>
						</div>
					</div>
				</div>
			</Container>
		</div>
	);
};

export default RegisterUser;
