import React, { useState } from 'react';
import Container from "../components/Container";
import { motion, AnimatePresence } from 'framer-motion';
import Tabs from "../components/Tabs";
import UserCheckins from "../components/UserCheckins";
import DateCheckins from "../components/DateCheckins";
import MonthlyReport from "../components/MonthlyReport";
import Reports from '../components/Reports'; // Import the new Reports component

const Dashboard = () => {
	const [activeTab, setActiveTab] = useState('userCheckins');

	// Variants for tab animation (horizontal)
	const tabVariants = {
		initial: { opacity: 0, x: 100 },
		animate: { opacity: 1, x: 0 },
		exit: { opacity: 0, x: -100 },
	};

	// Custom container animation (vertical)
	const containerVariants = {
		hidden: { opacity: 0, y: 20 },
		visible: { opacity: 1, y: 0 },
		exit: { opacity: 0, y: -20 }
	};

	return (
		<Container>
			<Tabs activeTab={activeTab} setActiveTab={setActiveTab} />

			<AnimatePresence mode='wait'>
				{/* Motion div to wrap tab content */}
				<motion.div
					key={activeTab}
					variants={tabVariants}
					initial="initial"
					animate="animate"
					exit="exit"
					transition={{ type: 'spring', stiffness: 300, damping: 30 }}
				>
					{activeTab === 'userCheckins' && <UserCheckins />}
					{activeTab === 'dateCheckins' && <DateCheckins />}
					{activeTab === 'monthlyReport' && <MonthlyReport />}
					{activeTab === 'reports' && <Reports />}
				</motion.div>
			</AnimatePresence>
		</Container>
	);
};

export default Dashboard;
