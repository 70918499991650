import React, { useState } from 'react';
import {AuthProvider} from "./tools/AuthContext";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Login from "./pages/Login";
import ProtectedRoute from "./tools/ProtectedRoute";
import RegisterUser from "./pages/RegisterUser";
import Dashboard from "./pages/Dashboard";
import Navbar from "./components/nav";
import Register from "./pages/Register";
import {ToastProvider} from "./tools/ToastContext";
import AdminRoute from "./tools/AdminRoute";


const App = () => {


	return (
		<AuthProvider>
			<ToastProvider>
				<BrowserRouter>
					<Navbar />
					<Routes>
						<Route path="/login" element={<Login />} />
						<Route element={<ProtectedRoute />}>
							<Route path="/" element={<Dashboard />} />
							<Route path="/register" element={<RegisterUser />} />
						</Route>
						<Route element={<AdminRoute />}>
							<Route path="/admin" element={<Register />} />
						</Route>
					</Routes>
				</BrowserRouter>
			</ToastProvider>
		</AuthProvider>
	);
};

export default App;
