import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../api/firebaseConfig';
import {motion} from "framer-motion";
import {Attendance} from "../types";

// Define the structure of attendance documents
const checkinListVariants = {
	initial: {},
	animate: {
		transition: {
			staggerChildren: 0.2, // Adjust the delay between each list item animation
		},
	},
};

const checkinItemVariants = {
	initial: { opacity: 0, y: 20 }, // Start slightly below
	animate: { opacity: 1, y: 0, transition: { duration: 0.4 } }, // Fade in and move upwards
	exit: { opacity: 0, y: 20, transition: { duration: 0.2 } }, // Fade out and move down when exiting
};

const Calendar = ({ year, month, cardId, direction }: { year: number; month: number; cardId: string, direction: number }) => {
	const [attendance, setAttendance] = useState<Attendance[]>([]);
	const [selectedDayEvents, setSelectedDayEvents] = useState<Attendance | null>(null);
	const [selectedDate, setSelectedDate] = useState<string | null>(null); // State to track the selected date

	// Fetch attendance data for the current month from Firestore
	useEffect(() => {
		const fetchAttendance = async () => {
			const startDate = new Date(year, month - 1, 1);
			const endDate = new Date(year, month, 0);

			const attendanceQuery = query(
				collection(db, 'attendance'),
				where('cardId', '==', cardId),
				where('date', '>=', startDate.toISOString().split('T')[0]),
				where('date', '<=', endDate.toISOString().split('T')[0])
			);

			const querySnapshot = await getDocs(attendanceQuery);
			const fetchedAttendance: Attendance[] = [];

			querySnapshot.forEach((doc) => {
				const data = doc.data();
				if (data.date) {
					fetchedAttendance.push({
						date: data.date,
						checkEvents: data.checkEvents || [],
					});
				}
			});

			setAttendance(fetchedAttendance);
		};

		fetchAttendance();
	}, [year, month, cardId]);

	const variants = {
		enter: (direction: number) => ({
			x: direction > 0 ? 1000 : -1000,
			opacity: 0,
		}),
		center: {
			x: 0,
			opacity: 1,
		},
		exit: (direction: number) => ({
			x: direction < 0 ? 1000 : -1000,
			opacity: 0,
		}),
	};

	// Get the first day of the month (0 - Sunday, 1 - Monday, ..., 6 - Saturday)
	const firstDay = new Date(year, month - 1, 1).getDay();
	const adjustedFirstDay = (firstDay === 0 ? 6 : firstDay - 1); // Adjust to Monday start

	const daysInMonth = new Date(year, month, 0).getDate();
	const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']; // Shortened day names

	// Helper function to format date as 'YYYY-MM-DD'
	const formatDate = (day: number) => {
		return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
	};

	// Helper function to format Firestore timestamp
	const formatTimestamp = (timestamp: any) => {
		if (!timestamp) return 'Not checked out';
		const date = timestamp.toDate();
		return date.toLocaleTimeString(); // Format as you need (e.g., 'HH:MM AM/PM')
	};
	const getColor = (restaurantId: string) => {
		switch (restaurantId) {
			case 'mechelen': return 'bg-mechelen-500 text-black'
			case 'antwerp': return 'bg-antwerp-500 text-white'
			case 'smash': return 'bg-smash-500 text-white'
		}
	}

	return (
		<motion.div
			className="flex"
			custom={direction}
			initial="enter"
			animate="center"
			exit="exit"
			variants={variants}
			transition={{
				x: {type: 'spring', stiffness: 300, damping: 30},
				opacity: {duration: 0.2},
			}}
		>
			<div className="flex-1 p-4">
				<ul className="grid grid-cols-7 gap-3 text-center font-bold uppercase mt-5">
					{daysOfWeek.map((day, index) => (
						<li key={index} className="py-2 border rounded-2xl bg-gray-100">
							{day}
						</li>
					))}
				</ul>

				<ul className="grid grid-cols-7 gap-3 text-center mt-5">
					{/* Render empty cells for days before the first day of the month */}
					{Array.from({length: adjustedFirstDay}).map((_, index) => (
						<li key={index} className="py-4 border rounded-2xl bg-white"></li>
					))}

					{/* Render the days of the month */}
					{Array.from({length: daysInMonth}).map((_, day) => {
						const currentDay = day + 1;
						const formattedDate = formatDate(currentDay);

						// Check if there's attendance for the current day
						const hasAttendance = attendance.some((att) => att.date === formattedDate);

						// Determine if the current day is selected
						const isSelected = selectedDate === formattedDate;

						return (
							<li
								key={currentDay}
								className={`py-4 border-2 rounded-2xl relative cursor-pointer ${
									isSelected ? 'border-2 border-torch-red-500' : ''
								}`}
								onClick={() => {
									// Set selected day events for the sidebar
									const selectedEvents = attendance.find((att) => att.date === formattedDate);
									setSelectedDayEvents(selectedEvents || null);
									setSelectedDate(formattedDate); // Set the selected date
								}}
							>
								{currentDay}
								{hasAttendance && (
									<span
										className="block w-2 h-2 bg-torch-red-500 rounded-full absolute bottom-2 left-1/2 transform -translate-x-1/2"></span>
								)}
							</li>
						);
					})}
				</ul>
			</div>

			{/* Sidebar for event details */}
			<div className="w-1/3 p-4 border-l">
				<h3 className="font-bold text-lg">Check-Ins for Selected Day</h3>
				{selectedDayEvents ? (
					<motion.ul
						key={selectedDate}
						variants={checkinListVariants}
			            initial="initial"
						animate="animate"
						exit="exit">
						{selectedDayEvents.checkEvents.map((event, index) => (
							<motion.li
							           variants={checkinItemVariants} key={index} className={`mt-2 ${getColor(event.restaurantId)} rounded-2xl relative p-3`}>
								<p className="font-black uppercase">{event.restaurantId}</p>
								<p><strong>Check-In Time:</strong> {formatTimestamp(event.checkinTime)}</p>
								<p><strong>Check-Out Time:</strong> {formatTimestamp(event.checkoutTime)}</p>
							</motion.li>
						))}
					</motion.ul>
				) : (
					<p>No check-ins for this day.</p>
				)}
			</div>
		</motion.div>
	);
};

export default Calendar;
