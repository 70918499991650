import axios from 'axios';
import { CardData } from "../pages/RegisterUser";
import {Report} from "../types";

const apiUrl = process.env.REACT_APP_API_URL;

// Fetch card ID using Axios
export const fetchCardId = async (): Promise<CardData | null> => {
	try {
		const response = await axios.get(`${apiUrl}/api/card-data`);
		return response.data as CardData;
	} catch (error) {
		console.error('Error fetching card ID:', error);
		return null;
	}
};

// Submit data using Axios
export const submitData = async (name: string, cardId: string): Promise<void> => {
	const data = { name, cardId };
	try {
		console.log('Data to send:', data);
		await axios.post(`${apiUrl}/api/submit-data`, data);
	} catch (error) {
		console.error('Error submitting data:', error);
	}
};

export const generateReport = async (report: Report[]): Promise<void> => {
	try {
		const response = await axios.post(`${apiUrl}/api/report`, report, {
			responseType: 'blob', // Important to receive the file as a binary blob
		});

		// Create a blob from the response data and trigger file download
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', 'attendance_report.xlsx'); // Set the filename for download
		document.body.appendChild(link);
		link.click(); // Trigger the download
		link.remove(); // Clean up the link element after download

	} catch (error) {
		console.error('Error generating report:', error);
	}
};
