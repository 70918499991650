import React, { useState, useEffect } from 'react';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { setDoc, doc, collection, getDocs, deleteDoc } from "firebase/firestore";
import Container from "../components/Container";
import { auth, db } from "../api/firebaseConfig";
import {useToast} from "../tools/ToastContext";


interface AdminUser {
	name: string;
	email: string;
	restaurant: string;
	uid: string;
}

const Register = () => {

	const { addToast } = useToast();
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [restaurant, setRestaurant] = useState('all');
	const [users, setUsers] = useState<AdminUser[]>([]);

	// Fetch all registered users from Firestore
	const fetchUsers = async (): Promise<void> => {
		const querySnapshot = await getDocs(collection(db, "adminUsers"));
		const usersData: AdminUser[] = querySnapshot.docs.map((doc) => doc.data() as AdminUser);
		setUsers(usersData);
	};

	useEffect(() => {
		fetchUsers();
	}, []);

	const handleDelete = async (uid: string) => {
		try {
			const userDocRef = doc(db, "adminUsers", uid);
			await deleteDoc(userDocRef);
			addToast('success', "User deleted successfully");
			fetchUsers();
		} catch (error) {
			console.error("Error deleting user: ", error);
			alert("Failed to delete user");
		}
	};

	const handleRegister = async () => {
		try {
			// Register user in Firebase Authentication
			const userCredential = await createUserWithEmailAndPassword(auth, email, password);
			const user = userCredential.user;

			// Save restaurant and name to Firestore
			await setDoc(doc(db, "adminUsers", user.uid), {
				name: name,
				email: email,
				restaurant: restaurant,
				uid: user.uid
			});

			addToast('success', "User registered successfully.");
			fetchUsers(); // Refresh the list of users after registration
		} catch (error: any) {
			console.error("Error registering user: ", error);
			addToast('error', "Error registering user.");
		}
	};

	return (
		<div>
			<Container>
				<div className="grid grid-cols-2 gap-4">
					{/* Left Side: Registration Form */}
					<div className="border p-4 rounded-lg">
						<h1 className="text-2xl uppercase font-bold mb-4">Register New Admin</h1>
						<div className="space-y-4">
							<div className="flex items-center justify-between">
								<label className="w-1/3">Name</label>
								<input type="text" value={name} onChange={(e) => setName(e.target.value)}
								       className="w-2/3 p-2 rounded border"/>
							</div>

							<div className="flex items-center justify-between">
								<label className="w-1/3">Email</label>
								<input type="email" value={email} onChange={(e) => setEmail(e.target.value)}
								       className="w-2/3 p-2 rounded border"/>
							</div>

							<div className="flex items-center justify-between">
								<label className="w-1/3">Password</label>
								<input type="password" value={password} onChange={(e) => setPassword(e.target.value)}
								       className="w-2/3 p-2 rounded border"/>
							</div>

							<div className="flex items-center justify-between">
								<label className="w-1/3">Restaurant</label>
								<select value={restaurant} onChange={(e) => setRestaurant(e.target.value)}
								        className="w-2/3 p-2 rounded border">
									<option value="all">All</option>
									<option value="antwerp">Antwerp</option>
									<option value="smash">Smash</option>
									<option value="mechelen">Mechelen</option>
								</select>
							</div>
						</div>

						<div className="flex justify-center items-center">
							<button
								className="px-5 py-2 my-5 text-white font-bold cursor-pointer bg-torch-red-500 rounded-2xl"
								onClick={handleRegister}
							>
								Save
							</button>
						</div>
					</div>

					{/* Right Side: Display Registered Users */}
					<div className="border p-4 rounded-lg">
						<h1 className="text-2xl uppercase font-bold mb-4">Registered Users</h1>
						<ul className="space-y-4">
							{users.length > 0 ? (
								users.map((user) => (
									<li key={user.uid} className="border p-2 rounded-lg">
										<p><strong>Name:</strong> {user.name}</p>
										<p><strong>Email:</strong> {user.email}</p>
										<p><strong>Restaurant:</strong> {user.restaurant}</p>
										<button
											className="text-red-500 hover:underline mt-2"
											onClick={() => handleDelete(user.uid)}
										>
											Delete User
										</button>
									</li>
								))
							) : (
								<p>No users registered yet.</p>
							)}
						</ul>
					</div>
				</div>
			</Container>
		</div>
	);
};

export default Register;
