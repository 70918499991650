import React, { useEffect, useState } from "react";
import useCardId from "../hooks/useCardId";
import useAttendanceByDate from "../hooks/useAttendanceByDate";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import dayjs from 'dayjs'; // For handling time
import { db } from "../api/firebaseConfig"; // Import Firestore config
import { doc, updateDoc, getDoc, setDoc } from "firebase/firestore"; // Modular Firestore functions
import { Timestamp } from "firebase/firestore";
import {CheckEvent} from "../types"; // To handle Firestore Timestamp

export interface UpdateWorkedHoursProps {
	date?: string,
	cardId?: string,
	restaurantId?: string // Add restaurantId as a prop
}

export function UpdateWorkedHours({ date, cardId, restaurantId }: UpdateWorkedHoursProps) {
	const { user, loading: userLoading, error: userError } = useCardId(cardId!);
	const { attendance, loading: attendanceLoading, error: attendanceError } = useAttendanceByDate(cardId!, date!);

	const [checkInTime, setCheckInTime] = useState<number | null>(null);
	const [checkOutTime, setCheckOutTime] = useState<number | null>(null);
	const [workedHours, setWorkedHours] = useState<number>(0); // Track worked hours for the restaurant
	const [isNewEntry, setIsNewEntry] = useState<boolean>(false); // Track if we are creating a new entry

	useEffect(() => {
		if (attendance?.checkEvents?.length && restaurantId) {
			// Filter the check events by restaurantId
			const filteredEvents = attendance.checkEvents.filter(event => event.restaurantId === restaurantId);

			if (filteredEvents.length > 0) {
				const checkin = dayjs(filteredEvents[0].checkinTime.toDate()).unix();
				const checkout = filteredEvents[0].checkoutTime
					? dayjs(filteredEvents[0].checkoutTime.toDate()).unix()
					: checkin + 3600; // Default checkout time to 1 hour after check-in if null

				setCheckInTime(checkin);
				setCheckOutTime(checkout);

				// Get worked hours for the restaurantId
				const workedHoursForRestaurant = attendance.workedHours?.[restaurantId] || 0;
				setWorkedHours(workedHoursForRestaurant);
				setIsNewEntry(false); // We have existing data
			} else {
				// Handle the case where there are no events for the restaurant
				setCheckInTime(dayjs(`${date} 00:00`).unix());
				setCheckOutTime(dayjs(`${date} 23:59`).unix());
				setWorkedHours(0);
				setIsNewEntry(true); // No existing event for the restaurant, so this is a new entry
			}
		} else {
			// No attendance document or events at all for this date, set a full-day range (24h)
			setCheckInTime(dayjs(`${date} 00:00`).unix());
			setCheckOutTime(dayjs(`${date} 23:59`).unix());
			setWorkedHours(0);
			setIsNewEntry(true); // Mark it as a new entry
		}
	}, [attendance, restaurantId, date]);

	// Handle slider change
	const handleSliderChange = (value: number | number[]) => {
		if (Array.isArray(value)) {
			setCheckInTime(value[0]);
			setCheckOutTime(value[1]);
		}
	};

	// Save changes and recalculate worked hours
	const handleSave = async () => {
		if (!checkInTime || !checkOutTime || !cardId || !date || !restaurantId) return;

		// Modular Firestore syntax for getting the document
		const docRef = doc(db, 'attendance', `${cardId}_${date}`);
		const docSnapshot = await getDoc(docRef);

		let checkEvents = [];
		let workedHoursObj: { [key: string]: number } = {};

		if (docSnapshot.exists()) {
			// If the document exists, use its data
			checkEvents = docSnapshot.data()?.checkEvents || [];
			workedHoursObj = docSnapshot.data()?.workedHours || {};
		}

		// Check if the event for the given restaurant exists
		let eventToUpdate = checkEvents.find((event: CheckEvent) => event.restaurantId === restaurantId);
		if (!eventToUpdate) {
			// If no event exists for the restaurant, create a new one
			eventToUpdate = {
				restaurantId,
				checkinTime: Timestamp.fromMillis(checkInTime * 1000),
				checkoutTime: Timestamp.fromMillis(checkOutTime * 1000)
			};
			checkEvents.push(eventToUpdate);
		} else {
			// Update the check-in and check-out times
			eventToUpdate.checkinTime = Timestamp.fromMillis(checkInTime * 1000);
			eventToUpdate.checkoutTime = Timestamp.fromMillis(checkOutTime * 1000);
		}

		// Calculate worked hours in seconds
		const workedSeconds = (checkOutTime - checkInTime); // Times are in seconds
		const workedHours = workedSeconds / 3600; // Convert seconds to hours

		// Round worked hours to the nearest 15-minute increment (0.25 hours)
		const roundedWorkedHours = Math.round(workedHours / 0.25) * 0.25;

		// Update worked hours for the restaurant
		workedHoursObj[restaurantId] = roundedWorkedHours;

		// If it's a new entry, we use setDoc; otherwise, update the document
		if (isNewEntry) {
			await setDoc(docRef, {
				cardId: cardId,
				deviceId: 'user',
				checkEvents: checkEvents,
				workedHours: workedHoursObj,
				date: date
			});
		} else {
			await updateDoc(docRef, {
				checkEvents: checkEvents,
				workedHours: workedHoursObj
			});
		}

		console.log(`Saved check-in/out times and worked hours: ${roundedWorkedHours} hours for restaurantId ${restaurantId}`);
	};

	const formatTime = (timestamp: number) => dayjs.unix(timestamp).format('HH:mm');

	return (
		<div className="p-3">
			<h1 className="text-2xl font-semibold">Edit date: {date} for {user?.name}</h1>

			{attendanceLoading ? (
				<p>Loading attendance...</p>
			) : (
				attendance && checkInTime && checkOutTime && (
					<div className="mt-4">
						<p>Check-in: {formatTime(checkInTime)}</p>
						<p>Check-out: {formatTime(checkOutTime)}</p>

						<Slider
							range
							min={workedHours === 0 ? checkInTime - 12 * 3600 : checkInTime - 3600} // If worked hours are 0, set 12 hours before check-in
							max={workedHours === 0 ? checkInTime + 12 * 3600 : checkOutTime + 3600 * 4} // If worked hours are 0, set 12 hours after check-in
							value={[checkInTime, checkOutTime]}
							onChange={handleSliderChange}
							step={60} // Move in 1-minute steps
							marks={{
								[checkInTime]: formatTime(checkInTime),
								[checkOutTime]: formatTime(checkOutTime),
							}}
						/>

						<div className="flex justify-center">
							<button
								className=" bg-torch-red-500 text-white py-2 px-4 rounded-2xl mt-10"
								onClick={handleSave}
							>
								Save Changes
							</button>
						</div>
					</div>
				)
			)}

			{attendanceError && <p className="text-red-500">{attendanceError}</p>}
		</div>
	);
}
