// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
	apiKey: "AIzaSyAaGkHD0x_-aqUhfi3Yb0nOsxFepr2ig0g",
	authDomain: "beastiecheckpoint.firebaseapp.com",
	projectId: "beastiecheckpoint",
	storageBucket: "beastiecheckpoint.appspot.com",
	messagingSenderId: "1076537091127",
	appId: "1:1076537091127:web:e4cc9eaae70df81cc5cbb2",
	measurementId: "G-46PYVN5HZP"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);


export {app, auth, db, storage};
