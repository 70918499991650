import React, { createContext, useContext, useState, ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ToastContainer from "../components/Toast/Toast";

interface Toast {
	id: string;
	type: 'success' | 'error' | 'warning';
	message: string;
}

interface ToastContextType {
	addToast: (type: 'success' | 'error' | 'warning', message: string) => void;
	removeToast: (id: string) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const ToastProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [toasts, setToasts] = useState<Toast[]>([]);

	const addToast = (type: 'success' | 'error' | 'warning', message: string) => {
		const id = uuidv4();
		setToasts((prevToasts) => [...prevToasts, { id, type, message }]);
	};

	const removeToast = (id: string) => {
		setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
	};

	return (
		<ToastContext.Provider value={{ addToast, removeToast }}>
			{children}
			<ToastContainer toasts={toasts} removeToast={removeToast} />
		</ToastContext.Provider>
	);
};

export const useToast = () => {
	const context = useContext(ToastContext);
	if (!context) {
		throw new Error('useToast must be used within a ToastProvider');
	}
	return context;
};
