import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';

interface MonthPickerProps {
	selectedMonth: number;
	setSelectedMonth: (month: number) => void;
	selectedYear: number;
	setSelectedYear: (year: number) => void;
}

const MonthPicker: React.FC<MonthPickerProps> = ({ selectedMonth, setSelectedMonth, selectedYear, setSelectedYear }) => {
	const [dimensions, setDimensions] = useState<{ width: number; left: number }>({ width: 0, left: 0 });
	const monthRefs = useRef<(HTMLButtonElement | null)[]>([]);
	const months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
		'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
	];

	useLayoutEffect(() => {
		if (monthRefs.current[selectedMonth]) {
			const { offsetWidth, offsetLeft } = monthRefs.current[selectedMonth]!;
			setDimensions({ width: offsetWidth, left: offsetLeft });
		}
	}, [selectedMonth]);

	return (
		<div className="relative flex justify-center mb-4">
			<div className="relative w-full">
				{months.map((month, index) => (
					<button
						key={index}
						ref={(el) => (monthRefs.current[index] = el)}
						className={`px-4 py-2 font-black uppercase ${selectedMonth === index ? 'text-white' : 'text-black'}`}
						onClick={() => setSelectedMonth(index)}
					>
						{month}
					</button>
				))}
				<motion.div
					className="absolute bottom-0 left-0 h-full bg-torch-red-500 z-[-1] rounded-2xl"
					animate={{ width: dimensions.width, x: dimensions.left }}
					transition={{ type: 'spring', stiffness: 300, damping: 30 }}
				/>
			</div>
			<select
				value={selectedYear}
				onChange={(e) => setSelectedYear(Number(e.target.value))}
				className="ml-4 border rounded-md p-2"
			>
				{Array.from({ length: 5 }).map((_, i) => {
					const year = new Date().getFullYear() - i;
					return (
						<option key={year} value={year}>
							{year}
						</option>
					);
				})}
			</select>
		</div>
	);
};

export default MonthPicker;
