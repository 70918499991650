import React, { useLayoutEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';

interface MonthFilterProps {
	selectedMonth: number; // 0 for "All" if included, 1 for Jan, etc.
	setSelectedMonth: (month: number) => void;
	selectedYear: number;
	setSelectedYear: (year: number) => void;
	includeAllOption?: boolean; // Optional prop to include "All"
}

const MonthFilter: React.FC<MonthFilterProps> = ({
	                                                 selectedMonth,
	                                                 setSelectedMonth,
	                                                 selectedYear,
	                                                 setSelectedYear,
	                                                 includeAllOption
                                                 }) => {
	const [dimensions, setDimensions] = useState<{ width: number; left: number }>({ width: 0, left: 0 });
	const monthRefs = useRef<(HTMLButtonElement | null)[]>([]);

	// Months array with optional "All"
	const months = [
		...(includeAllOption ? ['All'] : []), // Conditionally add "All"
		'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
		'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
	];

	// Adjust for selected month to account for "All" option if included
	useLayoutEffect(() => {
		// If "All" is included, selectedMonth corresponds directly
		// If "All" is not included, subtract 1 from selectedMonth to adjust for zero-based indexing
		const monthIndex = includeAllOption ? selectedMonth : selectedMonth - 1;
		if (monthRefs.current[monthIndex]) {
			const { offsetWidth, offsetLeft } = monthRefs.current[monthIndex]!;
			setDimensions({ width: offsetWidth, left: offsetLeft });
		}
	}, [selectedMonth, includeAllOption]);

	// Function to handle month selection
	const handleSetSelectedMonth = (index: number) => {
		if (includeAllOption) {
			// Set the month directly when "All" is included
			setSelectedMonth(index);
		} else {
			// When "All" is not included, adjust index for 1-based month selection
			setSelectedMonth(index + 1);
		}
	};

	return (
		<div className="relative flex justify-center mb-4">
			<div className="relative w-full">
				{months.map((month, index) => (
					<button
						key={index}
						ref={(el) => (monthRefs.current[index] = el)}
						className={`px-4 py-2 font-black uppercase ${
							selectedMonth === (includeAllOption ? index : index + 1) ? 'text-white' : 'text-black'
						}`}
						onClick={() => handleSetSelectedMonth(index)}
					>
						{month}
					</button>
				))}
				<motion.div
					className="absolute bottom-0 left-0 h-full bg-torch-red-500 z-[-1] rounded-2xl"
					animate={{ width: dimensions.width, x: dimensions.left }}
					transition={{ type: 'spring', stiffness: 300, damping: 30 }}
				/>
			</div>
			<select
				value={selectedYear}
				onChange={(e) => setSelectedYear(Number(e.target.value))}
				className="ml-4 border rounded-md p-2"
			>
				{Array.from({ length: 5 }).map((_, i) => {
					const year = new Date().getFullYear() - i;
					return (
						<option key={year} value={year}>
							{year}
						</option>
					);
				})}
			</select>
		</div>
	);
};

export default MonthFilter;
