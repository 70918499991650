import { motion } from 'framer-motion';
import React from 'react';

interface ModalProps {
	isOpen: boolean;
	onClose: () => void;
	children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
	// Framer Motion variants for animations
	const backdrop = {
		visible: { opacity: 1 },
		hidden: { opacity: 0 }
	};

	const modal = {
		hidden: { opacity: 0, y: '-100vh' },
		visible: { opacity: 1, y: '0' }
	};

	if (!isOpen) return null;

	const handleBackdropClick = (e: React.MouseEvent) => {
		// Ensure the click is outside the modal content
		if (e.target === e.currentTarget) {
			onClose();
		}
	};

	return (
		<motion.div
			className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
			initial="hidden"
			animate="visible"
			exit="hidden"
			variants={backdrop}
			onClick={handleBackdropClick} // Handle clicks outside modal content
		>
			<motion.div
				className="bg-white rounded-lg shadow-lg p-8 w-1/3 relative"
				variants={modal}
				onClick={(e) => e.stopPropagation()} // Prevent click inside modal from closing it
			>
				<button
					onClick={onClose}
					className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
				>
					&times;
				</button>
				{children}
			</motion.div>
		</motion.div>
	);
};

export default Modal;
