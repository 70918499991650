import React from "react";
import { useAuth } from "./AuthContext";
import {Outlet, Navigate} from "react-router-dom";

const AdminRoute: React.FC = () => {
	const { currentUser, restaurant } = useAuth();

	return currentUser && restaurant == 'all' ? <Outlet /> : <Navigate to="/" />
};

export default AdminRoute;
