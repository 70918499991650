type ShiftItemProps = {
	shiftNumber: number;
	shift: { from: string; to: string; restaurantId: string };
	onChange: (key: 'from' | 'to' | 'restaurantId', value: string) => void;
	selectedRestaurants: { id: string; label: string }[];
	disabled: boolean; // New prop to disable inputs
};

const ShiftItem: React.FC<ShiftItemProps> = ({
	                                             shiftNumber,
	                                             shift,
	                                             onChange,
	                                             selectedRestaurants,
	                                             disabled // Accept the disabled prop
                                             }) => (
	<div className="border rounded-2xl mt-2">
		<h1 className="p-2 mx-5">Shift {shiftNumber}</h1>
		<div className="flex items-center">
			<div className="p-2 flex items-center mx-5">
				<input
					type="time"
					value={shift.from}
					onChange={(e) => onChange('from', e.target.value)}
					className="p-2 rounded mx-5 border"
					disabled={disabled} // Disable if needed
				/>
			</div>
			<h1>-</h1>
			<div className="p-2 flex items-center mx-5">
				<input
					type="time"
					value={shift.to}
					onChange={(e) => onChange('to', e.target.value)}
					className="p-2 rounded mx-5 border"
					disabled={disabled} // Disable if needed
				/>
			</div>
			<div className="p-2 flex items-center mx-5">
				<select
					value={shift.restaurantId}
					onChange={(e) => onChange('restaurantId', e.target.value)}
					className="p-2 rounded mx-5 border"
					disabled={disabled || selectedRestaurants.length === 1} // Disable if only one option
				>
					{/* Auto-select if there's only one restaurant */}
					{selectedRestaurants.length > 1 ? (
						<option value="">Select Restaurant</option>
					) : null}
					{selectedRestaurants.map((restaurant) => (
						<option key={restaurant.id} value={restaurant.id}>
							{restaurant.label}
						</option>
					))}
				</select>
			</div>
		</div>
	</div>
);

export default ShiftItem;
