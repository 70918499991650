import React, { useEffect } from "react";

interface ToastProps {
	id: string;
	type: 'success' | 'error' | 'warning';
	message: string;
	autoDismiss?: boolean;
	onClose: (id: string) => void;
}

const Toast: React.FC<ToastProps> = ({ id, type, message, autoDismiss = true, onClose }) => {
	useEffect(() => {
		if (autoDismiss) {
			const timer = setTimeout(() => {
				onClose(id); // Automatically dismiss after 15 seconds
			}, 15000);
			return () => clearTimeout(timer);
		}
	}, [autoDismiss, id, onClose]);

	const getIcon = () => {
		switch (type) {
			case 'success':
				return (
					<svg className="w-5 h-5 text-green-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
						<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
					</svg>
				);
			case 'error':
				return (
					<svg className="w-5 h-5 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
						<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
					</svg>
				);
			case 'warning':
				return (
					<svg className="w-5 h-5 text-orange-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
						<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z" />
					</svg>
				);
			default:
				return null;
		}
	};

	return (
		<div className={`flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow`}>
			<div className={`inline-flex items-center justify-center flex-shrink-0 w-8 h-8 bg-${type}-100 rounded-lg`}>
				{getIcon()}
			</div>
			<div className="ml-3 text-sm font-normal">{message}</div>
			<button onClick={() => onClose(id)} type="button"
			        className="ml-auto bg-white text-gray-400 hover:text-gray-900 rounded-lg p-1.5 inline-flex items-center justify-center h-8 w-8">
				<span className="sr-only">Close</span>
				<svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
					<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
					      d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7L1 13"/>
				</svg>
			</button>
		</div>
	);
};

interface ToastContainerProps {
	toasts: { id: string; type: 'success' | 'error' | 'warning'; message: string }[];
	removeToast: (id: string) => void;
}

interface ToastContainerProps {
	toasts: { id: string; type: 'success' | 'error' | 'warning'; message: string }[];
	removeToast: (id: string) => void;
}

const ToastContainer: React.FC<ToastContainerProps> = ({ toasts, removeToast }) => {
	return (
		<div className="fixed bottom-5 right-5 z-50 flex flex-col space-y-2">
			{toasts.map(toast => (
				<Toast key={toast.id} id={toast.id} type={toast.type} message={toast.message} onClose={removeToast} />
			))}
		</div>
	);
};


export default ToastContainer;
