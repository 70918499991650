import { motion } from 'framer-motion';
import { useRef, useState, useLayoutEffect } from 'react';

const Tabs = ({ activeTab, setActiveTab }: { activeTab: string, setActiveTab: (tab: string) => void }) => {
	const [dimensions, setDimensions] = useState<{ width: number, left: number }>({ width: 0, left: 0 });
	const userCheckinsRef = useRef<HTMLButtonElement | null>(null);
	const dateCheckinsRef = useRef<HTMLButtonElement | null>(null);
	const monthlyReportRef = useRef<HTMLButtonElement | null>(null);
	const reportsRef = useRef<HTMLButtonElement | null>(null); // New Reports tab

	useLayoutEffect(() => {
		// Update dimensions based on the active tab
		if (activeTab === 'userCheckins' && userCheckinsRef.current) {
			const { offsetWidth, offsetLeft } = userCheckinsRef.current;
			setDimensions({ width: offsetWidth, left: offsetLeft });
		} else if (activeTab === 'dateCheckins' && dateCheckinsRef.current) {
			const { offsetWidth, offsetLeft } = dateCheckinsRef.current;
			setDimensions({ width: offsetWidth, left: offsetLeft });
		} else if (activeTab === 'monthlyReport' && monthlyReportRef.current) {
			const { offsetWidth, offsetLeft } = monthlyReportRef.current;
			setDimensions({ width: offsetWidth, left: offsetLeft });
		} else if (activeTab === 'reports' && reportsRef.current) { // Handle Reports tab
			const { offsetWidth, offsetLeft } = reportsRef.current;
			setDimensions({ width: offsetWidth, left: offsetLeft });
		}
	}, [activeTab]);

	const getTextColor = (tab: string) => (activeTab === tab ? '#ffffff' : '#000000'); // Dynamic text color

	return (
		<div className="relative flex justify-center mb-4">
			<div className="relative w-full">
				{/* User Check-ins Tab */}
				<motion.button
					ref={userCheckinsRef}
					className="px-4 py-2 font-black uppercase"
					onClick={() => setActiveTab('userCheckins')}
					animate={{ color: getTextColor('userCheckins') }} // Animate text color
					transition={{ type: 'tween', duration: 0.3 }}
				>
					User Check-ins
				</motion.button>

				{/* Date Check-ins Tab */}
				<motion.button
					ref={dateCheckinsRef}
					className="px-4 py-2 font-black uppercase"
					onClick={() => setActiveTab('dateCheckins')}
					animate={{ color: getTextColor('dateCheckins') }} // Animate text color
					transition={{ type: 'tween', duration: 0.3 }}
				>
					Date Check-ins
				</motion.button>

				{/* Monthly Report Tab */}
				<motion.button
					ref={monthlyReportRef}
					className="px-4 py-2 font-black uppercase"
					onClick={() => setActiveTab('monthlyReport')}
					animate={{ color: getTextColor('monthlyReport') }} // Animate text color
					transition={{ type: 'tween', duration: 0.3 }}
				>
					Monthly Report
				</motion.button>

				{/* Reports Tab */}
				<motion.button
					ref={reportsRef}
					className="px-4 py-2 font-black uppercase"
					onClick={() => setActiveTab('reports')} // New tab for reports
					animate={{ color: getTextColor('reports') }} // Animate text color
					transition={{ type: 'tween', duration: 0.3 }}
				>
					Reports
				</motion.button>

				{/* Dynamic animated background */}
				<motion.div
					className="absolute bottom-0 left-0 h-full bg-torch-red-500 z-[-1] rounded-2xl"
					animate={{ width: dimensions.width, x: dimensions.left }}
					transition={{ type: 'spring', stiffness: 300, damping: 30 }}
				/>
			</div>
		</div>
	);
};

export default Tabs;
