import {useWidth} from "../../hooks/useWidth";
import RegularMenu from "./RegularMenu";
import HamburgerMenu from "./HamburgerMenu";
import {useAuth} from "../../tools/AuthContext";
import {signOut} from 'firebase/auth';
import {auth} from "../../api/firebaseConfig";

const Navbar = () => {
	const regular = useWidth();
	const { currentUser } = useAuth();


	if (!currentUser) {
		return null;
	}

	return  regular ? <RegularMenu /> : <HamburgerMenu />
}


export default Navbar;
