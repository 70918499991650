import React, { createContext, useContext, useEffect, useState, ReactNode } from "react";
import { User, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../api/firebaseConfig";

interface AuthContextType {
	currentUser: User | null;
	restaurant: string | null;  // Added restaurant ID here
	loading: boolean;
}

interface AuthProviderProps {
	children: ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = (): AuthContextType => {
	const context = useContext(AuthContext);
	if (context === undefined) {
		throw new Error("useAuth must be used within an AuthProvider");
	}
	return context;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
	const [currentUser, setCurrentUser] = useState<User | null>(null);
	const [restaurant, setRestaurant] = useState<string | null>(null);  // State to store restaurant ID
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, async (user) => {
			if (user) {
				setCurrentUser(user);

				// Fetch the user's restaurant info from Firestore
				const userDocRef = doc(db, "adminUsers", user.uid);
				const userDoc = await getDoc(userDocRef);

				if (userDoc.exists()) {
					const userData = userDoc.data();
					setRestaurant(userData.restaurant);  // Set the restaurant in state
				} else {
					setRestaurant(null);  // If no data, clear restaurant state
				}
			} else {
				setCurrentUser(null);
				setRestaurant(null);  // Reset the restaurant when logged out
			}
			setLoading(false);
		});

		return unsubscribe;
	}, []);

	return (
		<AuthContext.Provider value={{ currentUser, restaurant, loading }}>
			{!loading && children}
		</AuthContext.Provider>
	);
};
