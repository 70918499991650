import React, { useLayoutEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';

interface RestaurantPickerProps {
	selectedRestaurant: string;
	setSelectedRestaurant: (restaurant: string) => void;
}

const RestaurantPicker: React.FC<RestaurantPickerProps> = ({
	                                                           selectedRestaurant,
	                                                           setSelectedRestaurant,
                                                           }) => {
	const [dimensions, setDimensions] = useState<{ width: number; left: number }>({ width: 0, left: 0 });
	const restaurantRefs = useRef<(HTMLButtonElement | null)[]>([]);

	// Array of restaurant objects with value, label, and locations
	const restaurants = [
		{ value: 'smash', label: 'Smash'},
		{ value: 'antwerp', label: 'Antwerp'},
		{ value: 'mechelen', label: 'Mechelen'},
	];

	useLayoutEffect(() => {
		// Set dimensions of the selected restaurant for animation
		const selectedIndex = restaurants.findIndex(r => r.value === selectedRestaurant);
		if (restaurantRefs.current[selectedIndex]) {
			const { offsetWidth, offsetLeft } = restaurantRefs.current[selectedIndex]!;
			setDimensions({ width: offsetWidth, left: offsetLeft });
		}
	}, [selectedRestaurant]);

	return (
		<div className="relative flex justify-center mb-4">
			<div className="relative w-full">
				{restaurants.map((restaurant, index) => (
					<button
						key={restaurant.value}
						ref={(el) => (restaurantRefs.current[index] = el)}
						className={`px-4 py-2 font-black uppercase ${selectedRestaurant === restaurant.value ? 'text-white' : 'text-black'}`}
						onClick={() => setSelectedRestaurant(restaurant.value)}
					>
						{restaurant.label}
					</button>
				))}

				{/* Animated background for selected restaurant */}
				<motion.div
					className="absolute bottom-0 left-0 h-full bg-torch-red-500 z-[-1] rounded-2xl"
					animate={{ width: dimensions.width, x: dimensions.left }}
					transition={{ type: 'spring', stiffness: 300, damping: 30 }}
				/>
			</div>
		</div>
	);
};

export default RestaurantPicker;
