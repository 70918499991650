import React, {useState, useEffect} from 'react';
import {useAuth} from "../tools/AuthContext";
import {collection, query, where, getDocs} from "firebase/firestore";
import {db} from "../api/firebaseConfig";
import Calendar from "./Calendar";
import MonthPicker from "./MonthPicker";
import {Employee} from "../types";
import Loader from "./Loader";
import {motion} from "framer-motion";

const UserCheckins = () => {
	const {currentUser, restaurant} = useAuth();
	const [users, setUsers] = useState<Employee[]>([]);
	const [filteredUsers, setFilteredUsers] = useState<any[]>([]);
	const [selectedUser, setSelectedUser] = useState<any | null>(null);
	const [loadingUsers, setLoadingUsers] = useState(false);
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [selectedMonth, setSelectedMonth] = useState<number>(new Date().getMonth() + 1);
	const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
	const [direction, setDirection] = useState(0); // Track animation direction

	useEffect(() => {
		const fetchUsers = async () => {
			setLoadingUsers(true);
			try {
				let usersQuery;

				if (restaurant === "all") {
					usersQuery = collection(db, "users");
				} else {
					usersQuery = query(
						collection(db, "users"),
						where("restaurantIds", "array-contains", restaurant)
					);
				}

				const querySnapshot = await getDocs(usersQuery);
				const usersList = querySnapshot.docs.map(doc => doc.data() as Employee);

				setUsers(usersList);
				setFilteredUsers(usersList);
			} catch (error) {
				console.error("Error fetching users: ", error);
			} finally {
				setLoadingUsers(false);
			}
		};

		fetchUsers();
	}, [restaurant]);

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value.toLowerCase();
		setSearchTerm(value);
		setFilteredUsers(users.filter(user => user.name.toLowerCase().includes(value)));
	};

	const handleUserClick = (user: any) => {
		setSelectedUser(user);
	};

	const handleMonthChange = (newMonth: number) => {
		setDirection(newMonth > selectedMonth ? 1 : -1); // Adjust the sliding direction
		setSelectedMonth(newMonth);
	};

	const userListVariants = {
		initial: {},
		animate: {
			transition: {
				staggerChildren: 0.15, // Delay between each child's animation
			},
		},
	};

	const userItemVariants = {
		initial: { opacity: 0, y: 20 }, // Starting from slightly below
		animate: { opacity: 1, y: 0, transition: { duration: 0.4 } }, // Fade in and move to the original position
		exit: { opacity: 0, y: 20, transition: { duration: 0.2 } }, // Fade out with downward movement
	};


	return (
		<div>
			<div className="grid grid-cols-4 gap-6">
				<div className="col-span-1 border rounded-2xl">
					<motion.ul variants={userListVariants}
					           initial="initial"
					           animate="animate"
					           exit="exit"
					           className="p-4">
						<li className="py-2 border mt-2 transition rounded-2xl px-1 cursor-pointer">
							<input
								type="text"
								placeholder="Search Users"
								value={searchTerm}
								onChange={handleSearchChange}
								className="w-full px-2 py-1 rounded"
							/>
						</li>
						{loadingUsers ? (
							<div className="flex justify-center py-4">
								<Loader/>
							</div>
						) : filteredUsers.length > 0 ? (
							filteredUsers.map((user, index) => (
								<motion.li
									key={index}
									variants={userItemVariants}
									className={`
										p-2 px-5 border mt-2 hover:bg-torch-red-500 hover:text-white transition rounded-2xl cursor-pointer
										${selectedUser === user ? 'bg-torch-red-500 text-white' : ''}
									`}
									onClick={() => handleUserClick(user)}
								>
									{user.name}
								</motion.li>
							))
						) : (
							<li>No users found</li>
						)}
					</motion.ul>
				</div>

				<div className="col-span-3 border rounded-2xl p-4">
					{selectedUser && (
						<>
							<MonthPicker
								selectedMonth={selectedMonth}
								setSelectedMonth={handleMonthChange}
								selectedYear={selectedYear}
								setSelectedYear={setSelectedYear}
							/>
							<Calendar
								year={selectedYear}
								month={selectedMonth + 1}
								cardId={selectedUser.cardId}
								direction={direction} // Pass direction to Calendar for animation
							/>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default UserCheckins;
