import React, { useEffect, useState } from 'react';
import { useAuth } from "../tools/AuthContext";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../api/firebaseConfig";
import {Attendance, CheckEvent, Report} from "../types";
import {saveAs} from "file-saver";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {generateReport} from "../api/apiService";
import {motion} from "framer-motion";
import Loader from "./Loader";
import MonthPicker from "./MonthPicker";
import RestaurantPicker from "./RestaurantPicker";
import Modal from "./Modal";
import {UpdateWorkedHours} from "./UpdateWorkedHours";
import CountingNumber from "./CountingNumber";


const MonthlyReport = () => {
	const [users, setUsers] = useState<any[]>([]);
	const [loadingUsers, setLoadingUsers] = useState(false);
	let {restaurant} = useAuth();
	const [selectedRestaurant, setSelectedRestaurant] = useState(restaurant === 'all' ? 'smash' : restaurant!);
	const [attendanceData, setAttendanceData] = useState<{ [key: string]: Attendance[] }>({});
	const [selectedMonth, setSelectedMonth] = useState<number>(new Date().getMonth());
	const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
	const [isModalOpen, setModalOpen] = useState(false);
	const [selectedData, setSelectedData] = useState<{ cardId?: string, date?: string }>();



	// Function to generate days of the month
	const generateDaysOfMonth = (month: number, year: number) => {
		const daysInMonth = new Date(year, month + 1, 0).getDate(); // Get the total number of days in the month
		const days = [];

		for (let day = 1; day <= daysInMonth; day++) {
			const date = new Date(year, month, day);
			const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'short' }); // e.g., "Mon", "Tue"
			days.push({ day, dayOfWeek });
		}

		return days;
	};

	const daysOfMonth = generateDaysOfMonth(selectedMonth, selectedYear);

	// Fetch users based on the restaurant
	useEffect(() => {
		const fetchUsers = async () => {
			setLoadingUsers(true);
			try {
				let usersQuery;

				if (restaurant === "all") {
					usersQuery = collection(db, "users");
				} else {
					usersQuery = query(
						collection(db, "users"),
						where("restaurantIds", "array-contains", restaurant)
					);
				}

				const querySnapshot = await getDocs(usersQuery);
				const usersList = querySnapshot.docs.map(doc => doc.data());

				setUsers(usersList);
			} catch (error) {
				console.error("Error fetching users: ", error);
			} finally {
				setLoadingUsers(false);
			}
		};

		fetchUsers();
	}, [restaurant]);

	// Fetch attendance data for each user based on the selected month
	useEffect(() => {
		const fetchAttendanceData = async () => {
			const fetchedData: { [key: string]: Attendance[] } = {};

			for (let user of users) {
				const cardId = user.cardId;
				const attendanceQuery = query(
					collection(db, "attendance"),
					where("cardId", "==", cardId),
					where("date", ">=", `${selectedYear}-${String(selectedMonth + 1).padStart(2, '0')}-01`),
					where("date", "<=", `${selectedYear}-${String(selectedMonth + 1).padStart(2, '0')}-${daysOfMonth.length}`)
				);

				const attendanceSnapshot = await getDocs(attendanceQuery);
				const attendanceList = attendanceSnapshot.docs.map(doc => doc.data()) as Attendance[];

				fetchedData[cardId] = attendanceList;
			}

			setAttendanceData(fetchedData);
		};

		if (users.length > 0) {
			fetchAttendanceData();
		}
	}, [users, selectedMonth, selectedYear]);

	// Function to round hours to the nearest 0.25 (15-minute intervals)
	const roundToQuarterHour = (hours: number) => {
		// Round to nearest 15 minutes (0.25 of an hour)
		return Math.round(hours * 4) / 4;
	};

	// Calculate total hours worked per day, rounded to the nearest 0.25
	const calculateHoursWorked = (checkEvents: CheckEvent[]) => {
		let totalHours = 0;
		checkEvents.forEach(event => {
			if (event.checkoutTime) {
				const checkinTime = event.checkinTime.toDate();
				const checkoutTime = event.checkoutTime.toDate();
				const hours = (checkoutTime.getTime() - checkinTime.getTime()) / (1000 * 60 * 60); // Convert ms to hours
				totalHours += hours;
			}
		});
		return roundToQuarterHour(totalHours).toFixed(2); // Return as string with 2 decimal places
	};

	// Calculate total hours for a user across all days of the month
	const calculateTotalHoursForUser = (user: any) => {
		let totalHours = 0;

		daysOfMonth.forEach((date) => {
			const formattedDate = `${selectedYear}-${String(selectedMonth + 1).padStart(2, '0')}-${String(date.day).padStart(2, '0')}`;
			const attendanceForDay = attendanceData[user.cardId]?.find(attendance => attendance.date === formattedDate);
			if (attendanceForDay) {
				const dailyHours = attendanceForDay?.workedHours ? attendanceForDay?.workedHours[selectedRestaurant] ? attendanceForDay?.workedHours[selectedRestaurant]: 0 : 0;
				totalHours += dailyHours;
			}
		});

		return totalHours.toFixed(2);
	};

	// Calculate total hours worked by all users for a specific day
	const calculateTotalHoursForDay = (day: number) => {
		let totalHours = 0;
		const formattedDate = `${selectedYear}-${String(selectedMonth + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;

		users.forEach(user => {
			const attendanceForDay = attendanceData[user.cardId]?.find(attendance => attendance.date === formattedDate);
			if (attendanceForDay) {
				const dailyHours = attendanceForDay?.workedHours ? attendanceForDay?.workedHours[selectedRestaurant] ? attendanceForDay?.workedHours[selectedRestaurant]: 0 : 0;
				totalHours += dailyHours;
			}
		});

		return totalHours.toFixed(2);
	};

	const exportToPDF = () => {
		const doc = new jsPDF({
			orientation: 'landscape', // Change to landscape
			format: 'a3' // Use A3 page size
		});

		// Add title and metadata
		doc.setFontSize(18);
		doc.text("Monthly Timesheet", 10, 10);

		doc.setFontSize(12);
		doc.text("Client: __________", 10, 20);
		doc.text(`Start Week Containing:`, 10, 30);
		doc.text(`Week Ending:`, 10, 40);

		// Prepare table data
		const tableColumn = [
			"Name",
			...daysOfMonth.map(date => `${date.day} (${date.dayOfWeek})`), // Include both day and weekday
			"Total Hours"
		];

		const tableRows: any = [];

		users.forEach(user => {
			const row = [
				user.name,
				...daysOfMonth.map(date => {
					const formattedDate = `${selectedYear}-${String(selectedMonth + 1).padStart(2, '0')}-${String(date.day).padStart(2, '0')}`;
					const attendanceForDay = attendanceData[user.cardId]?.find(attendance => attendance.date === formattedDate);
					return attendanceForDay ? calculateHoursWorked(attendanceForDay.checkEvents) : "0"; // Convert to "0" instead of 0.00
				}),
				calculateTotalHoursForUser(user) // Total hours for user
			];
			tableRows.push(row);
		});

		// Add footer totals row: Total Hours per day for all users
		const totalHoursRow = [
			"Total Hours",
			...daysOfMonth.map(date => calculateTotalHoursForDay(date.day)), // Total hours per day
			"" // Empty cell for the final column (Total Hours sum)
		].map(value => (value === "0" ? "0" : Math.floor(Number(value)))); // Ensure integer display

		tableRows.push(totalHoursRow);

		// Add regular hours row
		const regularHoursRow = [
			"Regular Hours",
			...daysOfMonth.map(() => totalHoursRow[daysOfMonth.length]), // Same as Total Hours for each day
			totalHoursRow[daysOfMonth.length] // Total regular hours for all users
		].map(value => (value === "0" ? "0" : Math.floor(Number(value)))); // Ensure integer display

		tableRows.push(regularHoursRow);

		// Add overtime hours row
		const otHoursRow = [
			"OT Hours",
			...daysOfMonth.map(() => "0"), // Always 0 for each day
			"0" // Total OT hours for all users
		];
		tableRows.push(otHoursRow);

		// Add table to the PDF with autoTable
		autoTable(doc, {
			head: [tableColumn],
			body: tableRows,
			startY: 50, // Start after the metadata
			theme: 'grid',
			styles: { fontSize: 10 },
			headStyles: { fillColor: [169, 169, 169] }, // Gray header color
			footStyles: { fillColor: [169, 169, 169] }  // Gray footer color
		});

		// Access the final Y position where the table ends
		const finalY = (doc as any).lastAutoTable.finalY || 50;

		// Add footer text below the table, with a margin from the table
		const footerStartY = finalY + 20; // Adjust as needed for the gap
		doc.text("Employee Signature: ________________________ Date: " + new Date().toLocaleDateString(), 10, footerStartY);
		doc.text("_________________________________________ (Supervisor Signature) Date", 10, footerStartY + 10);
		doc.text("Supervisor Name: ______________________", 10, footerStartY + 20);

		// Save the PDF
		doc.save(`timesheet_${selectedMonth + 1}_${selectedYear}.pdf`);
	};
	// Assuming you have access to the required variables like users, attendanceData, etc.
	const sendAttendanceDataToEndpoint = async () => {
		const userAttendanceData = users.map(user => {
			const hoursPerDay = daysOfMonth.map(date => {
				const formattedDate = `${selectedYear}-${String(selectedMonth + 1).padStart(2, '0')}-${String(date.day).padStart(2, '0')}`;
				const attendanceForDay = attendanceData[user.cardId]?.find(attendance => attendance.date === formattedDate);
				const hoursWorked = attendanceForDay ? parseFloat(calculateHoursWorked(attendanceForDay.checkEvents)) : 0;

				return {
					day: date.day,
					name: date.dayOfWeek, // Use dayOfWeek for the name (optional)
					worked: hoursWorked,
				};
			});

			return {
				name: user.name,
				hours: hoursPerDay,
			};
		});


		await generateReport(userAttendanceData); // Call the generateReport function with the data
	};
	// Custom container animation (vertical)
	const containerVariants = {
		hidden: { opacity: 0, y: 20 },
		visible: { opacity: 1, y: 0 },
		exit: { opacity: 0, y: -20 }
	};
	const monthTransition = {
		type: "spring",
		stiffness: 300,
		damping: 30,
	};


	const handleSelect = (cardId: string, date: string) => {
		console.log("CardID: ", cardId, "Day", date);
		setSelectedData({cardId, date});
		setModalOpen(true)
	}


	return (
		<motion.div className="w-full"
		            variants={containerVariants}
		            initial="hidden"
		            animate="visible"
		            exit="exit"
		            transition={{ type: 'spring', stiffness: 300, damping: 30 }}>
			{loadingUsers && (<Loader />)}
			{!loadingUsers && (
				<div className="">
					<div className="">
						<MonthPicker selectedMonth={selectedMonth} setSelectedMonth={setSelectedMonth} selectedYear={selectedYear} setSelectedYear={setSelectedYear} />
						{restaurant === 'all' ? (
							<RestaurantPicker
								selectedRestaurant={selectedRestaurant}
								setSelectedRestaurant={setSelectedRestaurant}
							/>
						) : (
							<RestaurantPicker
								selectedRestaurant={selectedRestaurant}
								setSelectedRestaurant={() => {}} // Disable changing restaurant
							/>
						)}
					</div>
					<div className="">
						<motion.div key={selectedMonth}
						            initial={{opacity: 0, x: -20}}
						            animate={{opacity: 1, x: 0}}
						            exit={{opacity: 0, x: 20}}
						            transition={monthTransition}>
							<div className="overflow-x-scroll">
								<table className="table-auto border-collapse border border-gray-400 w-full min-w-max">
									<thead>
									<tr>
										<th className="px-4 py-2 border border-gray-300">Name</th>
										{daysOfMonth.map((date, index) => (
											<th key={`day-${index}`}
											    className="px-4 py-2 border border-gray-300 whitespace-nowrap">
												{date.day}
											</th>
										))}
										<th className="px-4 py-2 border border-gray-300">Total Hours</th>
									</tr>
									<tr>
										<th className="px-4 py-2 border border-gray-300"></th>
										{daysOfMonth.map((date, index) => (
											<th key={`dayOfWeek-${index}`}
											    className="px-4 py-2 border border-gray-300 whitespace-nowrap">
												{date.dayOfWeek}
											</th>
										))}
										<th className="px-4 py-2 border border-gray-300"></th>
									</tr>
									</thead>
									<tbody>
									{!loadingUsers && users.length > 0 && users.map((user, userIndex) => (
										<tr key={`user-${userIndex}`}>
											<td className="px-4 py-2 border border-gray-300">{user.name}</td>
											{daysOfMonth.map((date, dayIndex) => {
												const formattedDate = `${selectedYear}-${String(selectedMonth + 1).padStart(2, '0')}-${String(date.day).padStart(2, '0')}`;
												const attendanceForDay = attendanceData[user.cardId]?.find(attendance => attendance.date === formattedDate);

												// const hoursWorked = attendanceForDay ? calculateHoursWorked(attendanceForDay.checkEvents) : "0.00";


												return (
													<td key={`day-${dayIndex}`}
													    onDoubleClick={() => handleSelect(user.cardId, formattedDate)}
													    className="px-4 py-2 border border-gray-300 text-center">
														{attendanceForDay?.workedHours ? attendanceForDay?.workedHours[selectedRestaurant] ? attendanceForDay?.workedHours[selectedRestaurant]: 0 : 0}
														{/*<CountingNumber value={}></CountingNumber>*/}
													</td>
												);
											})}
											<td className="px-4 py-2 border border-gray-300 text-center font-bold">
												{calculateTotalHoursForUser(user)}
											</td>
										</tr>
									))}
									</tbody>
									<tfoot>
									<tr>
										<td className="px-4 py-2 border border-gray-300 font-bold">Total Hours</td>
										{daysOfMonth.map((date, dayIndex) => (
											<td key={`total-day-${dayIndex}`}
											    className="px-4 py-2 border border-gray-300 text-center font-bold">
												{calculateTotalHoursForDay(date.day)}
											</td>
										))}
										<td className="px-4 py-2 border border-gray-300 text-center font-bold">
											{/* You can also calculate total monthly hours for all users if needed */}
											{/* e.g., total of the `calculateTotalHoursForUser(user)` */}
										</td>
									</tr>
									<tr>
										<td className="px-4 py-2 border border-gray-300 font-bold">Regular Hours</td>
										{daysOfMonth.map((date, dayIndex) => (
											<td key={`regular-day-${dayIndex}`}
											    className="px-4 py-2 border border-gray-300 text-center font-bold">
												{calculateTotalHoursForDay(date.day)}
											</td>
										))}
										<td className="px-4 py-2 border border-gray-300 text-center font-bold">
											{/* Similar logic for total regular hours */}
										</td>
									</tr>
									<tr>
										<td className="px-4 py-2 border border-gray-300 font-bold">OT Hours</td>
										{daysOfMonth.map((date, dayIndex) => (
											<td key={`ot-day-${dayIndex}`}
											    className="px-4 py-2 border border-gray-300 text-center font-bold">
												0.00
											</td>
										))}
										<td className="px-4 py-2 border border-gray-300 text-center font-bold">
											0.00
										</td>
									</tr>
									</tfoot>
								</table>
							</div>
						</motion.div>
					</div>

					<div className="flex justify-center items-center mt-5 ">
						<button onClick={sendAttendanceDataToEndpoint}
						        className="bg-torch-red-500 text-white px-4 py-2 mb-4 rounded-2xl uppercase font-black">
							Export as CSV
						</button>
					</div>

					<Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
						<UpdateWorkedHours cardId={selectedData?.cardId} date={selectedData?.date} restaurantId={selectedRestaurant} />
					</Modal>
				</div>
			)}
		</motion.div>
	);
};

export default MonthlyReport;
