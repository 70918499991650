import { useState } from "react";
import beastieBooking from "../../assets/img/logos/logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faAddressBook, faCalendar,
	faLock,
	faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import {signOut} from "firebase/auth";
import {auth} from "../../api/firebaseConfig";
import {useAuth} from "../../tools/AuthContext";

function RegularMenu() {

	let { restaurant } = useAuth();

	const handleLogout = async () => {
		try {
			await signOut(auth); // Call Firebase's signOut function
			console.log("User successfully logged out");
		} catch (error) {
			console.error("Error logging out:", error);
		}
	};

	return (
		<div className="relative flex justify-between w-[90%] mx-auto my-4">
			<div className="p-3 flex items-center justify-between w-full">
				<div className="w-[15%] p-3">
					<img src={beastieBooking} alt="Beastie Burgers" />
				</div>

				<div className="flex justify-center items-center">
					<div
						className="bg-torch-red-500 flex text-white rounded-full shadow px-10 p-4 gap-7 justify-evenly items-center">
						<a href="/" className="cursor-pointer">
							<FontAwesomeIcon icon={faCalendar} size="xl"/>
						</a>
						<a href="/register" className="cursor-pointer">
							<FontAwesomeIcon icon={faAddressBook} size="xl"/>
						</a>
						{restaurant == 'all' && (
							<a href="/admin" className="cursor-pointer">
								<FontAwesomeIcon icon={faLock} size="xl"/>
							</a>
						)}
						<div className="cursor-pointer" onClick={handleLogout}>
							<FontAwesomeIcon icon={faRightFromBracket} size="xl"/>
						</div>
					</div>
				</div>
			</div>

		</div>
	);
}

export default RegularMenu;
