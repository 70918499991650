import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // Import the calendar's CSS
import { useAuth } from "../tools/AuthContext";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../api/firebaseConfig";
import { saveAs } from 'file-saver'; // Ensure file-saver is imported
import {motion} from 'framer-motion';
import Tippy from "@tippyjs/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faClose} from "@fortawesome/free-solid-svg-icons";
import Loader from "./Loader";

// Define the Attendance component
const UsersByDateCheckins = () => {
	const { restaurant } = useAuth();
	const [selectedDate, setSelectedDate] = useState<Date | null>(new Date()); // Allow null for unselected dates
	const [users, setUsers] = useState<any[]>([]);
	const [loading, setLoading] = useState(false);
	const [filteredUsers, setFilteredUsers] = useState<any[]>([]);

	// Function to format the date as "YYYY-MM-DD" and ensure local time zone handling
	const formatDate = (date: Date) => {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		return `${year}-${month}-${day}`;
	};

	// Function to fetch attendance data for the selected date
	const fetchUsersByDate = async (date: Date) => {
		setLoading(true);
		const formattedDate = formatDate(date);
		try {
			// Query attendance collection by the selected date
			const attendanceQuery = query(
				collection(db, "attendance"),
				where("date", "==", formattedDate)
			);
			const attendanceSnapshot = await getDocs(attendanceQuery);
			const attendanceDataList: any[] = [];

			// Collect attendance data (cardId and checkEvents)
			attendanceSnapshot.forEach((doc) => {
				const attendanceData = doc.data();
				if (attendanceData.checkEvents.length > 0) {
					attendanceDataList.push({
						cardId: attendanceData.cardId,
						checkEvents: attendanceData.checkEvents
					});
				}
			});

			// Fetch the corresponding users by their cardId
			const userPromises = attendanceDataList.map(async (attendanceData) => {
				const userDoc = await getDoc(doc(db, "users", attendanceData.cardId));
				if (userDoc.exists()) {
					return {
						...userDoc.data(),          // Merge user data
						checkEvents: attendanceData.checkEvents // Attach checkEvents to the user
					};
				} else {
					return null;
				}
			});

			const usersList = (await Promise.all(userPromises)).filter(user => user !== null);
			setUsers(usersList);
			setFilteredUsers(usersList);
		} catch (error) {
			console.error("Error fetching users by date: ", error);
		} finally {
			setLoading(false);
		}
	};


	// Handle the date selection from the calendar
	const handleDateChange = (date: any) => {
		if (date && !Array.isArray(date)) {
			setSelectedDate(date); // Ensure it's a Date object and not an array
		}
	};

	// Fetch users when a date is selected
	useEffect(() => {
		if (selectedDate) {
			fetchUsersByDate(selectedDate);
		}
	}, [selectedDate]);

	// Function to export the users as a CSV file
	const exportToCSV = () => {
		const header = "Name,Email,Checkin Time,Checkout Time,Restaurant\n";
		let csvContent = '';

		// Iterate over filtered users and their checkEvents
		filteredUsers.forEach(user => {
			if (Array.isArray(user.checkEvents)) {
				user.checkEvents.forEach((event: any) => {
					const checkinTime = event.checkinTime ? event.checkinTime.toDate().toISOString() : "N/A";
					const checkoutTime = event.checkoutTime ? event.checkoutTime.toDate().toISOString() : "N/A";
					const row = `${user.name},${user.email},${checkinTime},${checkoutTime},${event.restaurantId}\n`;
					csvContent += row;
				});
			}
		});

		if (csvContent === '') {
			console.log("No data to export");
		} else {
			const blob = new Blob([header + csvContent], { type: 'text/csv;charset=utf-8;' });
			saveAs(blob, `checkins_${formatDate(selectedDate || new Date())}.csv`);
		}
	};


	const rowVariants = {
		hidden: { opacity: 0, y: 10 },
		visible: { opacity: 1, y: 0 },
	};

	const containerVariants = {
		hidden: { opacity: 0, y: 20 },
		visible: { opacity: 1, y: 0 },
		exit: { opacity: 0, y: -20 }
	};



	return (
		<div className="flex">
			{/* Left side: Calendar */}
			<div className="w-1/3 p-4">
				<h2>Select a Date:</h2>
				<Calendar
					onChange={handleDateChange}
					value={selectedDate}
					className="rounded-2xl" // Calendar container
					tileClassName={({date, view}) =>
						view === 'month' && selectedDate && date.toDateString() === selectedDate.toDateString()
							? 'bg-torch-red-500 text-white rounded-full !important' // Ensuring selected date is red and fully rounded
							: 'hover:bg-gray-200 rounded-full' // Optional hover effect for non-selected dates
					}
				/>
				<div className="flex">
					<button
						className="mt-4 p-2 bg-torch-red-500 text-white rounded-2xl hover:bg-torch-red-800 transition-all"
						onClick={exportToCSV}
						disabled={filteredUsers.length === 0}
					>
						Export to CSV
					</button>
				</div>
			</div>

			{/* Right side: Users List */}
			{loading && <Loader />}
			{!loading && (
				<div className="w-2/3 p-4">
					{loading ? (
						// Loading animation
						<motion.div
							initial="hidden"
							animate="visible"
							exit="exit"
							variants={containerVariants}
							transition={{type: 'spring', stiffness: 100, damping: 20}}
						>
							<div className="flex justify-center items-center">
								<div className="loader">Loading...</div>
								{/* Replace with your loading animation if you have a spinner */}
							</div>
						</motion.div>
					) : filteredUsers.length > 0 ? (
						// Animate user list when data is loaded
						<motion.div
							initial="hidden"
							animate="visible"
							exit="exit"
							variants={containerVariants}
							transition={{type: 'spring', stiffness: 100, damping: 20}}
						>
							<table className="table-auto w-full bg-white rounded-lg overflow-hidden">
								<thead className="bg-torch-red-500 text-white">
								<tr>
									<th className="px-4 py-2">Name</th>
									<th className="px-4 py-2">Email</th>
									<th className="px-4 py-2">Checkin Time</th>
									<th className="px-4 py-2">Checkout Time</th>
									<th className="px-4 py-2">Restaurant</th>
								</tr>
								</thead>
								<tbody className="divide-y divide-gray-200">
								{filteredUsers.map((user: any, index: number) => {
									// Collect all restaurant IDs and combine into a string separated by commas
									const allRestaurants = user.checkEvents.map((event: any) => event.restaurantId).join(', ');

									return (
										<motion.tr
											key={index}
											className="hover:bg-gray-100 transition duration-200 ease-in-out cursor-pointer"
											initial="hidden"
											animate="visible"
											variants={rowVariants}
											transition={{delay: index * 0.1}}
										>
											<td className="p-4 text-center">{user.name}</td>
											<td className="p-4 text-center">{user.email}</td>

											{/* Check-in Tooltip with multiple check-in times */}
											<td className="p-4 text-center">
												<div className="flex justify-center items-center">
													{user.checkEvents.length > 0 ? (
														<Tippy
															content={
																<div
																	className="bg-torch-red-500 p-5 text-white rounded-lg shadow-md">
																	{user.checkEvents.map((event: any, idx: number) => (
																		<div key={idx}>
																			{event.checkinTime
																				? `${event.restaurantId}: ` + event.checkinTime.toDate().toLocaleTimeString('en-GB', {
																				hour: '2-digit',
																				minute: '2-digit',
																				hour12: false
																			})
																				: 'N/A'}
																		</div>
																	))}
																</div>
															}
														>
															<div className="flex items-center">
																<FontAwesomeIcon icon={faCheck}
																                 className="text-green-500"/>
															</div>
														</Tippy>
													) : (
														<FontAwesomeIcon icon={faClose} className="text-red-500"/>
													)}
												</div>
											</td>

											{/* Check-out Tooltip with multiple check-out times */}
											<td className="p-4">
												<div className="flex justify-center items-center">
													{user.checkEvents.every((event: any) => event.checkoutTime) ? (
														<Tippy
															content={
																<div
																	className="bg-torch-red-500 p-5 text-white rounded-lg shadow-md">
																	{user.checkEvents.map((event: any, idx: number) => (
																		<div key={idx}>
																			{event.checkoutTime
																				? `${event.restaurantId}: ` + event.checkoutTime.toDate().toLocaleTimeString('en-GB', {
																				hour: '2-digit',
																				minute: '2-digit',
																				hour12: false
																			})
																				: 'N/A'}
																		</div>
																	))}
																</div>
															}
														>
															<div className="flex items-center">
																<FontAwesomeIcon icon={faCheck}
																                 className="text-green-500"/>
															</div>
														</Tippy>
													) : (
														<FontAwesomeIcon icon={faClose} className="text-red-500"/>
													)}
												</div>
											</td>

											{/* Restaurants displayed as a comma-separated string */}
											<td className="text-center p-4">{allRestaurants}</td>
										</motion.tr>
									);
								})}
								</tbody>
							</table>
						</motion.div>
					) : (
						// Animate "No users" message if there are no users
						<motion.div
							initial="hidden"
							animate="visible"
							exit="exit"
							variants={containerVariants}
							transition={{type: 'spring', stiffness: 100, damping: 20}}
						>
							No users checked in on this date.
						</motion.div>
					)}
				</div>
			)}
		</div>
	);
};

export default UsersByDateCheckins;
