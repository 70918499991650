import {useEffect, useState} from "react";
import {Attendance, Employee} from "../types";
import {collection, doc, getDoc, getDocs} from "firebase/firestore";
import {db} from "../api/firebaseConfig";


const useCardId = (id: string) => {
	const [user, setUser] = useState<Employee | undefined>();
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		if (!id) return
		const fetchUser = async () => {
			setLoading(true);
			try {
				const userRef = doc(db, 'users', id);
				const userDoc = await getDoc(userRef);
				setUser(userDoc.data() as Employee);
			} catch (error: any) {
				setError(error);
			} finally {
				setLoading(false);
			}
		}

		fetchUser();

	}, [id]);
	return { user, loading, error };
}

export default useCardId;
