import { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import {db} from "../api/firebaseConfig";
import {Attendance} from "../types"; // Modular Firestore imports


const useAttendanceByDate = (cardId: string, date: string) => {
	const [attendance, setAttendance] = useState<Attendance | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		if (!cardId || !date) return;

		const fetchAttendance = async () => {
			setLoading(true);
			try {
				// Create a query to get attendance data by cardId and date
				const attendanceCollection = collection(db, 'attendance');
				const attendanceQuery = query(
					attendanceCollection,
					where('cardId', '==', cardId),
					where('date', '==', date)
				);

				// Execute the query
				const querySnapshot = await getDocs(attendanceQuery);

				if (!querySnapshot.empty) {
					// If data is found, set the first match as attendance
					setAttendance(querySnapshot.docs[0].data() as Attendance);
				} else {
					setAttendance(null); // No attendance found for the given date
				}
			} catch (err) {
				setError('Failed to fetch attendance');
			} finally {
				setLoading(false);
			}
		};

		fetchAttendance();
	}, [cardId, date]);

	return { attendance, loading, error };
};

export default useAttendanceByDate;
